import styled from "styled-components";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column", paddingTop: "4rem" })}
    ${tablet({ padding: "0rem 0", display: "flex", flexDirection: "column" })}
`;
const Title = styled.h1`
    padding: 0 2rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    color: #ac997b;
    text-align: center;
    margin: 0rem 0rem 0rem 0;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "0 2rem",
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "0 2rem",
    })}
`

const AboutContainer = styled.div`
    display: flex;
    justify-content: center;
    ${mobile({ display: "block" })}
    ${tablet({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.6rem;
    font-weight: 300;
    margin-top: 1.5rem;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
    ${tablet({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    items-align: center;
    order: "1"
    ${mobile({ height: "50vh" })}
    ${tablet({ height: "100%" , paddingTop: "6rem" })}
`;

const LeftB = styled.div`
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    items-align: center;
    order: "1"
    ${mobile({ height: "50vh" })}
    ${tablet({ height: "100%" ,paddingTop: "6rem", paddingBottom: "6rem" })}
`;


const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/HAOFA_Tourbillon_Y3OC2yOA6.png?updatedAt=1697887472008");
    background-size: contain;
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
        transform: scale(1.1);
        -webkit-transition: transform 0.6s ease-in-out;
    }
    ${mobile({
        margin: "0rem",
        height: "40vh",
        width: "100vw",
    })}
    ${tablet({
        margin: "0rem",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageB = styled.div`
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/1901新版详情_05_soL3iIBuO.webp");
    background-size: contain;
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
        transform: scale(1.1);
        -webkit-transition: transform 0.6s ease-in-out;
    }
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "40vh",
        width: "100vw",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
    })}
    ${tablet({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
    })}
`;

const ImageC = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/1901%E6%96%B0%E7%89%88%E8%AF%A6%E6%83%85_23_UK7uFvdPg.png?updatedAt=1693905375622");
    background-size: contain;
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
        transform: scale(1.1);
        -webkit-transition: transform 0.6s ease-in-out;
    }
    ${mobile({
        display: "none"
    })}
    ${tablet({
        display: "none"
    })}
`;

const About = () => {
    return (
        <>
            <Container>
                <LeftA>
                    <Title>INDEPENDENT WATCHMAKER SINCE 1996 </Title>
                    <AboutContainer>
                        <Desc>
                            With our independent watchmaking technology,
                            our Double Tourbillon and Carousel series watches
                            rank among the top in the world in terms of
                            technology. In 2022, we proudly invented and
                            produced the world's first oval mini tourbillon
                            movement with a 40-hour power reserve.
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageA></ImageA>
            </Container>
            <Container>
                <ImageB></ImageB>
                <LeftA>
                    <Title>QUALITY IS OUR PRIORITY</Title>
                    <AboutContainer>
                        <Desc>
                            Throughout the years, we've produced a wide range of
                            stylish and exquisite watches with our In-house
                            Tourbillon, competing at the international level. We
                            utilize the same or even better materials as top
                            brands, including Carbon TPT, Crystal Glass, a
                            Titanium-magnesium alloy (aviation-grade material)
                            case, and Ceramic.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <LeftB>
                    <Title>3-YEAR WARRANTY</Title>
                    <AboutContainer>
                        <Desc>
                            We want to make sure you enjoy every HAOFA moment
                            with a worry-free experience. Enjoy up to 3 years of
                            warranty with every HAOFA timepiece purchase.
                        </Desc>
                    </AboutContainer>
                </LeftB>
                <ImageC></ImageC>
            </Container>
        </>
    );
};

export default About;
